/* style.css */

.custom-nav-link {
  font-family: "Roboto Black";
  display: inline-block;
  width: 200px;
  padding: 10px;
  margin: 10px;
  color: white;
  background-color: #4178bf;
  border-radius: 15px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  max-width: 200px ;
  text-align: center;
  font-size:17px;
}

.custom-nav-link:hover {
  background-color: #f2b29b;
  color:white;
}

.custom-nav-toggle{
  background-color: #4178bf;
  border: 1px solid white;
}

.custom-container{
  justify-content: center;
}

.test-item{
  border: 1px solid rgba(0, 0, 0, 0.151);
  position: relative; /* Добавлено для позиционирования кнопок */
  background-color: white;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  border-radius: 10px;
  text-align: left;
  color:black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  box-shadow: 0px 8px 7px 2px rgba(0, 0, 0, 0.151);
}

.exercise-item{

  position: relative; /* Добавлено для позиционирования кнопок */
  background-color: #f2b29b;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  border-radius: 10px;
  text-align: center;
  color:white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px; 
}

.result-item{
  background-color: white;
  width: 100%;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid blue;
}

.result-item:hover{
  transition: 0.5s;
  background-color:rgba(0, 0, 0, 0.062); 
}

.result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-header-content {
  flex: 1;
}

.result-header-percentage {
  margin-left: 20px;
}

.result-body {
  display: flex;
  flex-wrap: wrap;
}

.result-square {
  width: 20px;
  height: 20px;
  margin: 5px;
  border-radius: 5px;
}

.green {
  background-color: #39e3a2;
}

.red {
  background-color: #e33939;
}

.test-button{
  border:none;
  background: none;
}

.edit-button {
  position: absolute; /* Абсолютное позиционирование */
  top: -20px; /* Отступ сверху */
  right: -15px; /* Отступ справа */
  z-index: 10; /* Чтобы кнопка была поверх других элементов */
  background-image: url('https://cdn3.iconfinder.com/data/icons/feather-5/24/edit-512.png'); /* Путь к изображению */
  background-size: 30px; /* Масштабирование изображения */
  background-position: center; /* Позиционирование изображения по центру */
  background-repeat: no-repeat; /* Отключение повторения изображения */
  width: 40px; /* Ширина кнопки */
  height: 40px; /* Высота кнопки */
  border: none; /* Убираем границу кнопки */
  cursor: pointer; /* Изменение курсора при наведении */
}

.delete-button {
  position: absolute; /* Абсолютное позиционирование */
  top: -20px; /* Отступ сверху */
  left: -20px; /* Отступ слева */
  z-index: 10; /* Чтобы кнопка была поверх других элементов */
  background-image: url('https://cdn0.iconfinder.com/data/icons/google-material-design-3-0/48/ic_delete_forever_48px-256.png'); /* Путь к изображению */
  background-size: 35px; /* Масштабирование изображения */
  background-position: center; /* Позиционирование изображения по центру */
  background-repeat: no-repeat; /* Отключение повторения изображения */
  width: 40px; /* Ширина кнопки */
  height: 40px; /* Высота кнопки */
  border: none; /* Убираем границу кнопки */
  cursor: pointer; /* Изменение курсора при наведении */
}

/*мобильные устройства в портретном режиме*/
@media (max-width: 575.98px) {
  .test-item{
      text-align: left;
      padding: 20px;
      margin: 2px;
      margin-top: 20px;
  }
  .test-title{
      font-size: 30px;
  }
}
/*мобильные устроства в альболмном режиме*/
@media ((min-width:576px) and (max-width: 767.98px)) {

  .color,
  .color-2 {
    height: auto;
    padding: 20px;
  }
  .home-list {
    font-size: 20px;
  }

  .img-style {
    transform: scale(4);
    left: -200px;
    top: 550px;

  }

  .half-size {
    width: 100%;
  }

  .textarea,
  .area-1 {
    width: 100%;
  }

  .input-answer {
    width: 80px;
  }

  .white-text {
    font-size: 30px;
  }

  .test-item{
    margin-top: 40px;
  }
}

/*планшеты и всякое говно фром чайна*/
@media ((min-width:768px) and (max-width: 991.98px)) {
  .img-style {
    transform: scale(2);
  }
  .home-list {
    font-size: 20px;
  }
  .white-text{
    font-size: 26px;
  }
}

/*cumпьютеры и noтбуки*/
@media ((min-width:992px) and (max-width: 1199.98px)) {
  .img-style {
    transform: scale(1.41);
  }
  .home-list {
    font-size: 20px;
  }
  .white-text{
    font-size: 26px;
  }
}

/*бальшой кампьютер с кучей игр*/
@media (min-width:1200px) {
  .img-style {
    transform: scale(1.1);
  }
  .home-list {
    font-size: 20px;
  }
  .white-text{
    font-size: 26px;
  }
}
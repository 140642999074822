/* style.css */

.color {
  background-color: #4178bf;
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  /* Выравнивание по вертикали */
}

.color-2 {
  background-color: #d95252;
  height: auto;
  max-width: 100%;
  display: flex;
  align-items: center;
  /* Выравнивание по вертикали */
}

.description-test{
  border-style:none;
  color: black;
  border: 0.5px solid gray;
  border-radius: 10px;
  font-size: 18px;
  background: none;
}


.white-text {
  color: white;
  font-size: 30px;
  font-family: "Roboto";
  z-index: 2;
  position: relative;
}

.checkbox {
  color: black;
}

.btn {
  background-color: #4298e1;
  color: white;
  font-family: "Roboto";
}

.btn:hover{
  color: black;
  background-color: white;
}

.btn:hover {
  color: black;
  background-color: white;
}

.btn-blue {
  background-color: #4298e1;
  color: white;
  font-family: "Roboto";
}

.btn-red {
  background-color: #e43333;
  color: white;
  border: 1px solid red;
  font-family: "Roboto";
}

.btn-edit{
  position: absolute;
  border-radius: 50px;
  border: none;
  left: 620px;
}

.btn-blue:hover {
  color: black;
  background-color: white;
}
.btn-red:hover {
  color: black;
  background-color: white;
  border: 1px solid red;
}

.h-white {
  color: white;
  font-family: 'Roboto Black';
  font-size: 36px;
}

.h-black {
  color: black;
  font-family: 'Roboto Black';
  font-size: 36px;
}

.exercise-btn{
  transform: scale(1.3);
  margin: 10px;
  border: solid white 1px;
}

.save-btn{
  border-radius: 50px;
  border: 1px solid #4298e1;
  background-image: url("https://img.icons8.com/?size=100&id=18765&format=png&color=000000");
  background-position: center;
  background-size:26px;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  background-color:white;
}

.textarea {
  border-radius: 10px;
  border-style: solid;
  width: 70%;
  height: 500px;
  font-size: 36px;
  box-sizing: border-box;
}

.tale{
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.096);
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.title-test {
  background: none;
  color: black;
  font-size: 18px;  
  border: 0;
  border-style: none;
  width: 100%;
  border: 0.2px solid gray;
  border-radius: 10px;
}

.title-test:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

.half-size {
  max-width: 80%; /* Максимальная ширина 30% от родительского элемента */
  max-height: 100vh; /* Максимальная высота 30% от высоты видимой области */
  height: auto; /* Автоматическая высота, чтобы сохранить пропорции */
  display: block; /* Убедитесь, что изображение отображается как блочный элемент */
  margin: 0 auto; /* Центрирование изображения */
  object-fit: contain; /* Сохраняет пропорции изображения и масштабирует его, чтобы оно полностью поместилось в контейнер */
}

.input-style {
  border-radius: 10px;
  font-size: 30px;
  border: none;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.164);
}

.correct-answer {
  background-color: #28a745;
  /* Зеленый цвет для правильного ответа */
  color: white;
}

.delete-btn {
  border-radius: 50px;
  border: 1px solid #4298e1;
  background-image: url("https://img.icons8.com/?size=30&id=67884&format=png");
  background-position: center;
  background-size:30px;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  background-color: white;

}

.btn-exercise {
  width: 40px;
  height: 40px;
}

.input-answer {
  border-radius: 10px;
  width: 100px;
  border-style: dashed;
  display: inline-block;
  width: 100px;
  margin: 0 5px;
}

.input-exercise{
  border-radius:10px;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.selected {
  background-color: #007bff;
  color: white;
}

.half-size {
  width: 50%;
  display: block;
  margin: 0 auto;
}

.selected {
  background-color: #28a745;
}

.area-1 {
  width: 100%;
  height: 200px;
}

.img-style {
  position: relative;
  left: -80px;
  top: 70px;
  z-index: 1;
  

}
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-item {
  max-width: 100px;
  margin: 5px;
  cursor: grab;
}

.image-item:active {
  cursor: grabbing;
}

.home-list {
  font-size: 32px;
}


.toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
}

.bg-footer{
  background-color: #6f86ab;
}

.row-shadow{
  box-shadow: black ;
}

.a-correct{
  border: 3px solid rgb(57, 231, 51);
  background-color: white;
}

.a-incorrect{
  border: 3px solid rgb(238, 2, 2);
  background-color: white;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-item {
  max-width: 100px;
  margin: 5px;
  cursor: grab;
}

.image-item:active {
  cursor: grabbing;
}

/*мобильные устройства в портретном режиме*/
@media (max-width: 575.98px) {
  .img-style {
    display: none;
  }
  .home-list {
    font-size: 20px;
  }
  .white-text{
    font-size: 26px;
  }
  .title-test{
    font-size: 15px;
  }
  .btn-blue{
    transform: scale(1);
  }
  .input-answer{
    width: 200px;
  }
}

.input-answer.correct {
  background-color: green;
  color: white;
}

.input-answer.incorrect {
  background-color: red;
  color: white;
}

.correct {
  background-color: rgb(117, 207, 99) !important;
  color: white !important;
}

.incorrect {
  background-color: rgb(199, 60, 60) !important;
  color: white !important;
}

.correct-word {
  font-size: 0.8em;
  color: green;
}
/*мобильные устроства в альболмном режиме*/
@media ((min-width:576px) and (max-width: 767.98px)) {

  .color,
  .color-2 {
    height: auto;
    padding: 20px;
  }
  .home-list {
    font-size: 20px;
  }

  .img-style {
    transform: scale(2);
    left: -200px;
    top: 550px;

  }

  .half-size {
    width: 100%;
  }

  .textarea,
  .area-1 {
    width: 100%;
  }

  .input-answer {
    width: 80px;
  }

  .white-text {
    font-size: 30px;
  }
}

/*планшеты и всякое говно фром чайна*/
@media ((min-width:768px) and (max-width: 991.98px)) {
  .img-style {
    transform: scale(1);
  }
  .home-list {
    font-size: 20px;
  }
  .white-text{
    font-size: 26px;
  }
}

/*cumпьютеры и noтбуки*/
@media ((min-width:992px) and (max-width: 1199.98px)) {
  .img-style {
    width:500px;
  }
  .home-list {
    font-size: 20px;
  }
  .white-text{
    font-size: 26px;
  }
}

/*бальшой кампьютер с кучей игр*/
@media (min-width:1200px) {
  .img-style {
    width: 800px;
  }
  .home-list {
    font-size: 20px;
  }
  .white-text{
    font-size: 26px;
  }
}

/* Добавляем новые стили для кнопок */